import React from 'react';
import { useLocation } from 'react-router-dom';

import backgroundVideo from '../media/videos/backgroundVideo.mp4';
import Navbar from '../components/Navbar';
import { ReactComponent as Phone } from "../media/images/svg/phone.svg";

function MainScreen(props)
{
    const inHomePage = useLocation().pathname === "/";
     
    return (
        <div>
            { inHomePage ?
            <section id="mainScreen" className="container-fluid p-0">
                <div className="d-flex flex-column h-100">
                    <div className="row g-0">
                        <div id="callToday" className="d-flex justify-content-center col-12">
                            <a>Call Today:&nbsp;<a href="tel:3052056409">(305) 205-6409</a></a>
                        </div>
                        <Navbar/>
                        <div id="calculateBudget" className="d-flex justify-content-center col-12 p-3">
                            Click Here To Calculate Your Budget
                        </div>
                    </div>

                    <video 
                        autoPlay={true} 
                        loop={true}
                        controls={false} 
                        playsInline
                        muted id="mainScreenVideo">
                        <source src={backgroundVideo} type="video/mp4"/>
                    </video>

                    <div id="mainScreenWindow" className='d-none d-md-block'>
                        <a className="mb-5" href="tel:3052056409"><Phone className='accentColor'/> (305) 205-6409</a>
                        <h1>Electrical Solutions for Home & Business</h1>
                        <p>From installations to maintenance, we provide safe and efficient electrical services to power your future.</p>
                        <a className="whiteLink animated-link" href="/"> LEARN MORE</a>
                    </div>
                </div>
            </section>
            : <Navbar/> }
        </div>
    );
};

export default MainScreen;
