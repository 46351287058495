import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './css/main.css'
import './css/animations.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Loading from "./pages/loading.js";

import MainScreen from "./components/MainScreen.js"
import Footer from "./components/Footer.js"

const Home = lazy(() => import("./pages/home.js"));

const SmartHomeAutomation = lazy(() => import("./pages/smart-home-automation.js"));
const ComercialAutomation = lazy(() => import("./pages/comercial-automation.js"));
const AutomationWellness = lazy(() => import("./pages/automation-wellness.js"));
const SpacesHome = lazy(() => import("./pages/spaces-home.js"));
const SpacesComercial = lazy(() => import("./pages/spaces-comercial.js"));
const SpacesMDU = lazy(() => import("./pages/spaces-mdu.js"));
const Services = lazy(() => import("./pages/services.js"));
const UserExperience = lazy(() => import("./pages/user-experience.js"));
const Gallery = lazy(() => import("./pages/gallery.js"));

//const PrivacyPolicy = lazy(() => import("./pages/privacy-policy.js"));
//const CookiePolicy = lazy(() => import("./pages/cookie-policy.js"));
//const TermsAndConditions = lazy(() => import("./pages/terms-and-conditions.js"));

const NotFound = lazy(() => import("./pages/404.js"));

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Suspense fallback={<Loading/>}>
          <MainScreen/>
          <Routes>
              <Route exact path='/' element={<Home/>} />
              <Route exact path='/smart-home-automation' element={<SmartHomeAutomation/>} />
              <Route exact path='/comercial-automation' element={<ComercialAutomation/>} />
              <Route exact path='/automation-wellness' element={<AutomationWellness/>} />
              <Route exact path='/spaces-home' element={<SpacesHome/>} />
              <Route exact path='/spaces-comercial' element={<SpacesComercial/>} />
              <Route exact path='/spaces-mdu' element={<SpacesMDU/>} />
              <Route exact path='/services' element={<Services/>} />
              <Route exact path='/user-experience' element={<UserExperience/>} />
              <Route exact path='/gallery' element={<Gallery/>} />
              <Route path='*' element={<NotFound/>} />
          </Routes>
          <Footer/>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
