import React from 'react';

import { ReactComponent as Star } from "../media/images/svg/star.svg";
import { ReactComponent as Control4Logo } from "../media/images/footer-logos/control4-logo-white.svg";
import { ReactComponent as GoogleLogo } from "../media/images/svg/google_logo.svg";

import LutronLogo from "../media/images/footer-logos/Lutron-Logo.png";
import SavantLogo from "../media/images/footer-logos/Savant_WHITE.png";
import SonosLogo from "../media/images/footer-logos/sonos-white.png";

function Footer(props)
{
    return (
        <footer>
            <div className="container">
                <div className="row">
                    {/* First Column: Headquarters and Logos */}
                    <div className="col-8 col-lg-6 col-xl-4 mb-4 mx-auto">
                        <p><strong>Headquarters:</strong></p>
                        <a href="https://maps.app.goo.gl/R9s8ojY9xfTxPXQk8" className="animated-link" target="_blank" rel="noopener noreferrer">
                            3040 N 29th Ave #1<br />Hollywood, FL 33020
                        </a>
                        <br />
                        <a href="tel:3052056409" className="animated-link">(305) 205-6409</a>
                        <div className="row mt-4 justify-content-center">
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://www.lutron.com" target="_blank" rel="noopener noreferrer">
                                    <img src={LutronLogo} style={{ width: "190px" }} alt="Lutron Logo" />
                                </a>
                            </div>
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://www.savant.com" target="_blank" rel="noopener noreferrer">
                                    <img src={SavantLogo} style={{ width: "160px" }} alt="Savant Logo" />
                                </a>
                            </div>
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://www.sonos.com" target="_blank" rel="noopener noreferrer">
                                    <img src={SonosLogo} style={{ width: "100px" }} alt="Sonos Logo" />
                                </a>
                            </div>
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://www.control4.com" target="_blank" rel="noopener noreferrer">
                                    <Control4Logo style={{ width: "220px" }} alt="Control4 Logo" />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Second Column: Modern Links with hover effects */}
                    <div className="col-12 col-lg-6 col-xl-4 mb-4 d-flex justify-content-center">
                        <ul className="list-unstyled text-left">
                            <li className="mb-3"><a href="/residential-services" className="animated-link">Residential Services</a></li>
                            <li className="mb-3"><a href="/commercial-services" className="animated-link">Commercial Services</a></li>
                            <li className="mb-3"><a href="/electrical-maintenance" className="animated-link">Electrical Maintenance</a></li>
                            <li className="mb-3"><a href="/power-systems" className="animated-link">Power Systems</a></li>
                            <li className="mb-3"><a href="/lighting-solutions" className="animated-link">Lightning Solutions</a></li>
                            <li className="mb-3"><a href="/energy-efficiency" className="animated-link">Energy Efficiency</a></li>
                            <li className="mb-3"><a href="/services" className="animated-link">Services</a></li>
                            <li className="mb-3"><a href="https://building-dashboard.vherngames.com/" className="animated-link">Smart Building Dashboard</a></li>
                            <li className="mb-3"><a href="/safety-standards" className="safety-standards">Safety Standards</a></li>
                            <li className="mb-3"><a href="/gallery" className="animated-link">Gallery</a></li>
                            <li className="mb-3"><a href="/#contactUs" className="animated-link">Contact</a></li>
                        </ul>
                    </div>

                    {/* Third Column: Reviews and Ratings */}
                    <div className="col-lg-12 col-xl-4 mb-4 text-center order-md-last">
                        <div className="mb-3">
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                        </div>
                        <p className="smallLetter">Smart Tech Solutions is rated 5 out of 5 based on xx reviews from satisfied clients.</p>
                        <p>"always willing to help and fix all my problems. Joel has been a great envoy for the company. congratulations to you and to him"</p>
                        <div className="mb-2">
                            <GoogleLogo style={{ width: '3rem' }} alt="Google Logo" />
                        </div>
                        <p>– John D.</p>
                        <p className="accentColor animated-link">View full review here</p>
                    </div>
                </div>

                <div className="row">
                    <div id="divider" className="col-12 mb-3"></div>

                    {/* Social Media and Footer Links */}
                    <div className="col-12 col-md-6 mb-2 text-center text-md-left">
                        <a href="#" className="animated-link">Instagram</a> | <a href="#" className="animated-link">Twitter</a>
                    </div>
                    <div className="col-12 col-md-6 mb-2 text-center text-md-right">
                        <a href="#" className="animated-link">Privacy Policy</a> | <a href="#" className="animated-link">Sitemap</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
